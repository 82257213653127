/* eslint-disable */

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
// import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setMpinTrue } from 'redux/MpinModalSlice';

const useAuth = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('type');
    // const pathname = window.location.pathname;
    // console.log('pathname', pathname);
    // decode the token to get its payload
    // const decodedToken = jwt.decode(token, { complete: true });
    if (token) {
        if (role === 'merchants') {
            // console.log('token is present', token);
            const decoded = jwt_decode(token);
            // console.log('exp of token : ', decoded.exp);
            // console.log('time now : ', Date.now() / 1000);
            // console.log('time left : ', decoded.exp - Date.now() / 1000);
            // check if the token has expired
            if (decoded.exp < Date.now() / 1000) {
                // console.log('expired');
                // token has expired
                return 3;
            } else {
                // console.log('not expired');
                // token is still valid
                const user = token;
                return user && token;
            }
        } else {
            console.log('unautherized page, you are not a merchant');
            return 2;
        }
    } else {
        // console.log('token is nottt present');
        return 1;
    }
};

const ProtectedRoute = ({ element }) => {
    const isAuth = useAuth();
    if (isAuth) {
        if (isAuth === 1) {
            return <Navigate to="/" replace />;
        } else if (isAuth === 2) {
            const role = localStorage.getItem('type');
            if (role === 'distributor') {
                return <Navigate to="/distributor/dashboard" replace />;
            } else if (role === 'superdistributor') {
                return <Navigate to="/super-distributor/dashboard" replace />;
            } else {
                return <Navigate to="/unauthorized" replace />;
            }
        } else {
            return element;
        }
    } else {
        return <Navigate to="/" replace />;
    }
    // return isAuth ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
