import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OtpInput from 'react-otp-input';
import '../auth-forms/CustomOtpInput.css';
import { useEffect, useState } from 'react';
// import AcemoneyLogo from '../../../../assets/images/RADIANT_ACE_MONEY_MASTER_LOGOo.png';
import { handleCatchError } from 'utils/CatchHandler';
import Mlink from '@mui/material/Link';
// import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { useSelector } from 'react-redux';
import { setMpinFalse } from 'redux/MpinModalSlice';
import { useDispatch } from 'react-redux';
import '../../../../layout/MainLayout/modalStyles.css';
import { setUser } from 'redux/LoginSlice';
import { ToastContainer } from 'react-toastify';
import { setDisablerTrue, setDisablerFalse } from 'redux/SidebarDisablerSlice';
import { checkMpin, getMpinOtp, setMpins } from 'utils/API/MpinApiCalls';
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Mpin(props) {
    console.log('propsss', props);
    const [mpin, setMpin] = useState('');
    const [confirm_mpin, setconfirm_mpin] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [isLoading1, setisLoading1] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [validate_token, setValidate_token] = useState('');
    const [otp, setOtp] = useState('');
    const [forgotShow, setForgotShow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [token, setToken] = useState('');
    const [text, setText] = useState('');
    const dispatch = useDispatch();
    const mpinCheck = localStorage.getItem('PublicMessageStatus');
    const isPasswordValid = localStorage.getItem('isPasswordValid');
    const mode = useSelector((state) => state.theme.mode);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            setToken(accessToken);
        } else {
            const token = localStorage.getItem('LoginToken');
            setToken(token);
        }
    }, []);
    useEffect(() => {
        if (!isModalOpen) {
            dispatch(setDisablerFalse());
        }
        // eslint-disable-next-line
    }, [isModalOpen]);
    const showModal = () => {
        dispatch(setDisablerTrue());
        setIsModalOpen(true);
    };
    useEffect(() => {
        showModal();
        //eslint-disable-next-line
    }, []);
    const [settype1, setSettype] = useState('');
    // const mpinModalState = useSelector((state) => state?.mpinModalState?.yourStateMpin);
    const navigate = useNavigate();
    // const publicMessage = props?.publicMessage;
    //eslint-disable-next-line
    const mpinStatus = props?.status;
    useEffect(() => {
        if (mpinStatus) {
            setForgotShow(true);
        } else {
            setForgotShow(false);
        }
    }, [mpinStatus]);
    //eslint-disable-next-line
    const publicMessage = props?.publicMessage;
    const isMobile = window.innerWidth <= 768;
    const generateOtp = async (settype) => {
        setisLoading(true);
        try {
            // const token = localStorage.getItem('token');
            const agentid = localStorage.getItem('id');
            setSettype(settype);
            const payload = {
                mpin,
                settype,
                agentid
            };
            const response = await getMpinOtp(payload);
            // const response = await axiosInstanceLive.post(
            //     '/api/V2/moneytransfer/merchant/GenerateOTPMpin',
            //     { mpin, settype, agentid },
            //     {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //             'Content-Type': 'application/json'
            //         }
            //     }
            // );
            // console.log(response);
            setisLoading(false);
            setText(response?.data?.text);
            if (!response?.data?.status) {
                globalNotifyError(response?.data?.message);
                A;
                const errors = response?.data?.errors;
                const errorArrays = Object?.values(errors);
                const firstArray = errorArrays[0];
                globalNotifyError(firstArray[0]);
            } else {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.status);
                setValidate_token(response?.data?.validate_token);
            }
        } catch (error) {
            setisLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleSubmit = () => {
        console.log(mpin, confirm_mpin);
        if (mpin == confirm_mpin) {
            const Settype = 0;
            generateOtp(Settype);
        } else {
            globalNotifyError('MPIN do not match');
        }
    };
    const handleResend = () => {
        if (mpin === confirm_mpin) {
            const Settype = 1;
            generateOtp(Settype);
        } else {
            globalNotifyError('MPIN do not match');
        }
    };
    const handleSetMpin = async () => {
        setisLoading1(true);
        try {
            const agentid = localStorage.getItem('id');
            const settype = settype1;
            const payload = {
                mpin,
                settype,
                agentid,
                validate_token,
                otp
            };
            const response = await setMpins(payload);
            // const response = await axiosInstanceLive.post(
            //     '/api/V2/moneytransfer/merchant/SetMpin',
            //     { mpin, settype, agentid, validate_token, otp },
            //     {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //             'Content-Type': 'application/json'
            //         }
            //     }
            // );
            // console.log(response);
            setisLoading1(false);
            if (!response?.data?.status) {
                if (!response?.status === 201) {
                    globalNotifyError(response?.data?.message);
                }
                const errors = response?.data?.errors;
                const errorArrays = Object?.values(errors);
                const firstArray = errorArrays[0];
                globalNotifyError(firstArray[0]);
            } else {
                globalNotifySuccess(response?.data?.message);
                localStorage.setItem('accessToken', token);
                localStorage.setItem('token', response?.data?.refresh_token);
                setIsModalOpen(false);
                const bc_verification = localStorage.getItem('bcBankVerified');
                // if (!bc_verification) {
                //     navigate('/merchants/primary-profile-update');
                // } else if (!isPasswordValid) {
                //     navigate('/merchants/mandatory-password-reset');
                // } else if (publicMessage) {
                //     navigate('/public-message', { state: publicMessage });
                // } else {
                //     navigate('/merchant-home');
                // }
                if (!bc_verification) {
                    navigate('/merchants/primary-profile-update');
                } else if (!isPasswordValid) {
                    navigate('/merchants/mandatory-password-reset');
                } else if (publicMessage) {
                    navigate('/public-message', { state: publicMessage });
                } else {
                    navigate('/merchant-home');
                }
                // setRefresh_token(response?.data?.refresh_token);
            }
        } catch (error) {
            setisLoading1(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //check mpin
    const handleCheckMpin = async (mpin) => {
        setisLoading(true);
        try {
            const agentid = localStorage.getItem('id');
            const payload = {
                mpin,
                agentid
            };
            const response = await checkMpin(payload);
            dispatch(setUser(response?.data));
            const matm = response?.data?.icicStatus;
            const id = response.data?.uid;
            const email_id = response?.data?.userInfo?.email;
            const pin = response?.data.userInfo?.pin;
            const address = response?.data.userInfo?.address;
            const place = response?.data.userInfo?.place;
            const name = response?.data?.userInfo?.agname;
            const role = response?.data?.type;
            const fname = response?.data?.userInfo?.fname;
            const fnameMerchants = response?.data?.userInfo?.agname;
            const lname = response?.data?.userInfo?.lname;
            const phone = response?.data?.userInfo?.phone;
            const logo = response?.data?.logo_img;
            const dmtType = response?.data?.dmt_type;
            const agentCode = response.data?.agent_code;
            const bcAgreementStatus = response?.data?.bc_agreement?.status;
            const publicMessage = response?.data?.message?.text;
            const distributorEditOption = response?.data?.userInfo?.edit_option;
            const settlementPermission = response?.data?.settlement_type;
            const bc_verification = response?.data?.bc_verification;
            const employeeRole = response?.data?.role;
            const shopname = response?.data?.userInfo?.shop_name;
            const shopAddress = response?.data?.userInfo?.shop_address;
            const city = response?.data?.userInfo?.city;
            const recharge_vendor = response?.data?.recharge_vendor;
            const upi_atm_subscription = response?.data?.upi_atm_subscription;
            const isPasswordValid = response?.data?.passwordresetTag;
            const userinfoCategory = response?.data?.userInfo?.category;
            const darkModeStatus = response?.data?.userInfo?.dark_mode;
            if (darkModeStatus === 1) {
                if (mode === 'light') {
                    localStorage.setItem('mainThemeMode', 'dark');
                    dispatch(toggleTheme());
                }
            } else {
                if (mode === 'dark') {
                    localStorage.setItem('mainThemeMode', 'light');
                    dispatch(toggleTheme());
                }
            }
            if (response?.data?.type === 'merchants') {
                if (
                    response?.data?.role === 'employee' ||
                    response?.data?.role === 'teammanager' ||
                    response?.data?.role === 'teamleader'
                ) {
                    localStorage.setItem('isEmployee', true);
                    localStorage.setItem('merchantEmpName', response?.data?.employeeInfo?.name);
                } else {
                    localStorage.setItem('isEmployee', false);
                }
            }
            localStorage.setItem('darkModeStatus', darkModeStatus);
            localStorage.setItem('userinfoCategory', userinfoCategory);
            localStorage.setItem('id', id);
            localStorage.setItem('city', city);
            localStorage.setItem('firstName', fname);
            localStorage.setItem('LastName', lname);
            localStorage.setItem('fnameMerchant', fnameMerchants);
            localStorage.setItem('matm', matm);
            localStorage.setItem('user_id', id);
            localStorage.setItem('name', name);
            localStorage.setItem('email_id', email_id);
            localStorage.setItem('pin', pin);
            localStorage.setItem('place', place);
            localStorage.setItem('address', address);
            localStorage.setItem('role', role);
            localStorage.setItem('agentmobile', phone);
            localStorage.setItem('logoImage', logo);
            localStorage.setItem('agentcode', agentCode);
            localStorage.setItem('dmtType', dmtType);
            localStorage.setItem('bcAgreementStatus', bcAgreementStatus);
            localStorage.setItem('distributorEditOption', distributorEditOption);
            localStorage.setItem('type', response?.data?.type);
            localStorage.setItem('subrole', response?.data?.userInfo?.role);

            localStorage.setItem('settlementPermission', settlementPermission);
            localStorage.setItem('bcBankVerified', bc_verification);
            localStorage.setItem('employeeRole', employeeRole);
            localStorage.setItem('employeeFname', response?.data?.employeeInfo?.fname);
            localStorage.setItem('employeeLname', response?.data?.employeeInfo?.lname);
            localStorage.setItem('empId', response?.data?.eid);
            localStorage.setItem('BBPSOnboardStatus', response?.data?.billavenue_status);
            localStorage.setItem('shopname', shopname);
            localStorage.setItem('shopAddress', shopAddress);
            localStorage.setItem('recharge_vendor', recharge_vendor);
            localStorage.setItem('upi-atm-subscription', upi_atm_subscription);
            localStorage.setItem('category', response?.data?.userInfo?.category);
            localStorage.setItem('userInfo', JSON.stringify(response?.data?.userInfo));
            localStorage.setItem('isPasswordValid', isPasswordValid);
            setisLoading(false);
            if (!response?.data?.status) {
                // globalNotifyError(response?.data?.message);
            } else {
                globalNotifySuccess(response?.data?.message);
                localStorage.setItem('accessToken', token);
                localStorage.setItem('token', response?.data?.refresh_token);
                dispatch(setMpinFalse());
                setIsModalOpen(false);
                const mpinStatus = false;
                localStorage.setItem('mpinStatus', mpinStatus);
                if (mpinCheck === 'true') {
                    if (publicMessage) {
                        navigate('/public-message', { state: publicMessage });
                        window.location.reload(); //Temporary adjustment ,to be removed once the issue is fixed.
                    } else {
                        navigate('/merchant-home');
                        window.location.reload(); //Temporary adjustment ,to be removed once the issue is fixed.
                    }
                } else {
                    window.location.reload();
                }
                // setRefresh_token(response?.data?.refresh_token);
            }
        } catch (error) {
            setisLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleMpinSubmit = () => {
        if (mpin.length === 6) {
            handleCheckMpin(mpin);
        } else {
            globalNotifyError('The MPIN must consist of 6 digits');
        }
    };
    const handleMpinChange = (mpin) => {
        // console.log('otp in handle :', otp);
        if (/^\d*$/.test(mpin)) {
            setMpin(mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (mpin.length === 6) {
                handleCheckMpin(mpin);
            }
        }
    };
    const handleMpinChange1 = (mpin) => {
        // console.log('otp in handle :', otp);
        if (/^\d*$/.test(mpin)) {
            setMpin(mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (mpin.length === 6) {
                setMpin(mpin);
            }
        }
    };
    const handleMpinChange2 = (confirm_mpin) => {
        if (/^\d*$/.test(confirm_mpin)) {
            setconfirm_mpin(confirm_mpin);
            // If the entered value has reached 6 digits, trigger the submit function
            if (confirm_mpin.length === 6) {
                setconfirm_mpin(confirm_mpin);
            }
        }
    };
    const handleOtpChange = (otp) => {
        if (/^\d*$/.test(otp)) {
            setOtp(otp);
            // If the entered value has reached 6 digits, trigger the submit function
            if (otp.length === 6) {
                setOtp(otp);
            }
        }
    };
    const handleForgot = () => {
        setForgotShow(false);
        setMpin(false);
    };
    const handleBack = () => {
        console.log('first');
        setForgotShow(true);
    };
    return (
        <>
            <Modal
                title=""
                open={isModalOpen}
                closable={false}
                footer={null}
                className="custom-modal"
                wrapClassName="custom-modal-backdrop"
                centered
            >
                <>
                    <ThemeProvider theme={defaultTheme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                {/* <Box sx={{ m: 1 }}>
                                <img src={AcemoneyLogo} alt="Logo" width={480} />
                            </Box> */}
                                {/* <Typography component="h1" variant="h5">
                        mPIN
                    </Typography> */}
                                {!forgotShow && (
                                    <>
                                        {!showOtpField && (
                                            <>
                                                <Box
                                                    sx={{
                                                        marginTop: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'left'
                                                    }}
                                                >
                                                    <Button onClick={handleBack}>Back</Button>
                                                </Box>
                                                {/* <Box component="form" noValidate sx={{ mt: 1 }}> */}
                                                <p style={{ fontWeight: 'bold' }}>Enter MPIN</p>
                                                <OtpInput
                                                    value={mpin}
                                                    onChange={handleMpinChange1}
                                                    numInputs={6}
                                                    renderSeparator={
                                                        <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>
                                                    }
                                                    isInputSecure={true}
                                                    shouldAutoFocus={true}
                                                    inputStyle="custom-otp-input"
                                                    // inputType="number"
                                                    containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                                <p style={{ fontWeight: 'bold' }}>Confirm MPIN</p>
                                                <OtpInput
                                                    value={confirm_mpin}
                                                    onChange={handleMpinChange2}
                                                    numInputs={6}
                                                    renderSeparator={
                                                        <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>
                                                    }
                                                    isInputSecure={true}
                                                    inputStyle="custom-otp-input"
                                                    // inputType="number"
                                                    containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                                {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    onClick={handleSubmit}
                                                    disabled={isLoading}
                                                >
                                                    Generate OTP
                                                </Button>
                                            </>
                                        )}
                                        {showOtpField && (
                                            <>
                                                <p style={{ fontWeight: 'bold' }}>{text}</p>
                                                <p style={{ fontWeight: 'bold' }}>Enter OTP</p>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <OtpInput
                                                            value={otp}
                                                            onChange={handleOtpChange}
                                                            numInputs={6}
                                                            renderSeparator={
                                                                <span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>
                                                                    -
                                                                </span>
                                                            }
                                                            isInputSecure={true}
                                                            shouldAutoFocus={true}
                                                            inputStyle="custom-otp-input"
                                                            // inputType="number"
                                                            containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {/* <TextField
                                                            sx={{ mb: 1 }}
                                                            id="outlined-basic"
                                                            label="OTP Number"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            value={otp}
                                                            onChange={handleOtpChange}
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 1, mb: 2 }}
                                                    disabled={isLoading1}
                                                    onClick={handleSetMpin}
                                                >
                                                    Set MPIN
                                                </Button>
                                                <Grid container>
                                                    <Grid item>
                                                        <Mlink
                                                            component="button"
                                                            variant="body2"
                                                            onClick={handleResend}
                                                            style={{ float: 'right' }}
                                                        >
                                                            Resend OTP
                                                        </Mlink>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                                {forgotShow && (
                                    <>
                                        <p style={{ fontWeight: 'bold' }}>Enter MPIN</p>
                                        <OtpInput
                                            value={mpin}
                                            onChange={handleMpinChange}
                                            numInputs={6}
                                            renderSeparator={<span className={isMobile ? 'otp-separator-mobile' : 'otp-separator'}>-</span>}
                                            isInputSecure={true}
                                            shouldAutoFocus={true}
                                            inputStyle="custom-otp-input"
                                            // inputType="number"
                                            containerStyle={isMobile ? 'otp-container-mobile' : 'otp-container'}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleMpinSubmit}
                                            disabled={isLoading}
                                        >
                                            Submit
                                        </Button>
                                        <Grid container>
                                            <Grid item>
                                                <Mlink component="button" variant="body2" onClick={handleForgot} style={{ float: 'right' }}>
                                                    Forgot MPIN
                                                </Mlink>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {/* </Box> */}
                            </Box>
                        </Container>
                    </ThemeProvider>
                    <></>
                </>
            </Modal>

            <ToastContainer />
        </>
    );
}
