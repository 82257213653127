import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
let merchantEmpID;
const isEmployee = localStorage.getItem('isEmployee');
if (isEmployee === 'true') {
    merchantEmpID = localStorage.getItem('empId');
}
const merchantCommonPost = async (apiEndPoint, payload) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('LoginToken');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const POST_API_ENDPOINTS = {
    checkMpin: 'moneytransfer/merchant/CheckMpin',
    getOtpMpin: 'moneytransfer/merchant/GenerateOTPMpin',
    setMpin: 'moneytransfer/merchant/SetMpin',
    mpinStatusChecker: 'moneytransfer/merchant/StatusMpin'
    // Add more endpoints as needed
};
export const checkMpin = async (payload) => {
    //API for mpin check
    const response = await merchantCommonPost(POST_API_ENDPOINTS.checkMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setMpins = async (payload) => {
    //API for mpin set
    const response = await merchantCommonPost(POST_API_ENDPOINTS.setMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMpinOtp = async (payload) => {
    //API for mpin Otp
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getOtpMpin, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mpinStatusChecker = async (payload) => {
    const response = await merchantCommonPost(POST_API_ENDPOINTS.mpinStatusChecker, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
