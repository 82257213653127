/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
let merchantEmpID;
const isEmployee = localStorage.getItem('isEmployee');
if (isEmployee === 'true') {
    merchantEmpID = localStorage.getItem('empId');
}
export const merchantCommonPost = async (apiEndPoint, payload) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,

        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const merchantCommonGetRequest = async (apiEndPoint) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};

export const GET_API_ENDPOINTS = {
    checkPermission: 'merchants/checkPermission',
    rdService: 'merchants/rdService',
    //merchant employee apis
    merchantEmployeeList: 'merchants/employee/list'
};
export const POST_API_ENDPOINTS = {
    GETCheckPermission: 'merchants/checkPermission',
    checkMerchantOrBC: 'merchants/checkIsMerchant',
    convertBC: 'merchants/bcConvertion',
    searchServices: 'moneytransfer/search/GovtServices',
    getGovtMultiServices: 'moneytransfer/getGovtMultiServices',
    faqSearch: 'faq/search',
    updateBcAgentAccount: 'merchants/updateBcAgentAccount',
    agreePolicy: 'moneytransfer/terms/agreePolicy',
    //merchant employee apis
    addMerchantEmployee: 'merchants/employee/add',

    getNewToken: 'moneytransfer/merchant/refreshToken'
    // Add more endpoints as needed
};
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const checkPermissionGet = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.checkPermission)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const merchantEmployeeListApi = async () => {
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.merchantEmployeeList)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const rdServiceLinksApi = async (payload) => {
    //API FOR GETTING GOVT MULTI SERVICES
    const response = await merchantCommonGetRequest(GET_API_ENDPOINTS.rdService, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const checkMerchantOrBCAPI = async (payload) => {
    const loginRole = localStorage.getItem('role');

    //API FOR CHECKING IF THE USER IS BCAGENT OR MERCHANT
    if (loginRole === 'merchants') {
        const response = await merchantCommonPost(POST_API_ENDPOINTS.checkMerchantOrBC, payload)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return response;
    } else {
        return null;
    }
};

export const convertAsBc = async (payload) => {
    //API FOR CONVERTING MERCHANT INTO BCAGENT
    const response = await merchantCommonPost(POST_API_ENDPOINTS.convertBC, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const searchCommonServices = async (payload) => {
    //API FOR SEARCHING ALL THE COMMON SERVICES
    const response = await merchantCommonPost(POST_API_ENDPOINTS.searchServices, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const searchGovtMultiServices = async (payload) => {
    //API FOR GETTING GOVT MULTI SERVICES
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getGovtMultiServices, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const faqSearchApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.faqSearch, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const updateBcAgentAccountApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.updateBcAgentAccount, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const agreePolicyApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.agreePolicy, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const addMerchantEmployeeApi = async (payload) => {
    //API FOR FAQ SEARCH
    const response = await merchantCommonPost(POST_API_ENDPOINTS.addMerchantEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mpinTokenExtender = async (payload) => {
    const response = await merchantCommonPost(POST_API_ENDPOINTS.getNewToken, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
